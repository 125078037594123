const bag_arr = {
  20021456: {
    title: `레더 백`,
    img: `white`,
    price: 32000,
    status: [5, 4],
  },
  20021455: {
    title: `트윙클 클러치백`,
    img: `white`,
    price: 42000,
    status: [5, 4],
  },
  20021454: {
    title: `큐티 미니백`,
    img: `white`,
    price: 27000,
    status: [5, 4],
  },
  20021453: {
    title: `모카 사각 숄더백`,
    img: `white`,
    price: 33000,
    status: [5, 4],
  },
  20021452: {
    title: `빅 복조리백`,
    img: `white`,
    price: 30000,
    status: [5, 4],
  },
  20021451: {
    title: `캐스터너츠 크로스백`,
    img: `white`,
    price: 25000,
    status: [5, 4],
  },
  20021450: {
    title: `벚꽃 사각 미니백`,
    img: `white`,
    price: 29000,
    status: [5, 4],
  },
  // 20021449: {
  //   title: `temp_08`,
  //   img: `white`,
  //   price: 50000,
  //   status: [5,4]
  // },
  // 20021448: {
  //   title: `temp_09`,
  //   img: `white`,
  //   price: 50000,
  //   status: [5,4]
  // },
};

const color_obj = {
  0: "블랙",
  1: "브라운",
  2: "핑크",
  3: "화이트",
};

const order_list = [
  [
    {
      date: 20020415,
      stuff: 0,
      amount: 7,
    },
    {
      date: 20020415,
      stuff: 1,
      amount: 8,
    },
    {
      date: 20020415,
      stuff: 2,
      amount: 9,
    },
    {
      date: 20020415,
      stuff: 3,
      amount: 5,
    },
    {
      date: 20020415,
      stuff: 1,
      amount: 1,
    },
    {
      date: 20020415,
      stuff: 6,
      amount: 5,
    },
    {
      date: 20020415,
      stuff: 5,
      amount: 3,
    },
    {
      date: 20020415,
      stuff: 2,
      amount: 5,
    },
    {
      date: 20020415,
      stuff: 3,
      amount: 10,
    },
    {
      date: 20020415,
      stuff: 5,
      amount: 4,
    },
    {
      date: 20020415,
      stuff: 4,
      amount: 8,
    },
    {
      date: 20020415,
      stuff: 2,
      amount: 6,
    },
    {
      date: 20020415,
      stuff: 0,
      amount: 8,
    },
    {
      date: 20020415,
      stuff: 1,
      amount: 1,
    },
    {
      date: 20020415,
      stuff: 0,
      amount: 9,
    },
    {
      date: 20020415,
      stuff: 5,
      amount: 1,
    },
    {
      date: 20020415,
      stuff: 2,
      amount: 10,
    },
    {
      date: 20020415,
      stuff: 6,
      amount: 9,
    },
    {
      date: 20020415,
      stuff: 3,
      amount: 10,
    },
    {
      date: 20020415,
      stuff: 1,
      amount: 10,
    },
  ],
  [
    {
      date: 20020414,
      stuff: 124,
      amount: 7,
    },
    {
      date: 20020414,
      stuff: 321,
      amount: 8,
    },
    {
      date: 20020414,
      stuff: 522,
      amount: 5,
    },
    {
      date: 20020414,
      stuff: 33,
      amount: 5,
    },
    {
      date: 20020414,
      stuff: 18,
      amount: 1,
    },
    {
      date: 20020414,
      stuff: 67,
      amount: 5,
    },
    {
      date: 20020414,
      stuff: 65,
      amount: 3,
    },
    {
      date: 20020414,
      stuff: 27,
      amount: 5,
    },
    {
      date: 20020414,
      stuff: 83,
      amount: 3,
    },
    {
      date: 20020414,
      stuff: 95,
      amount: 4,
    },
    {
      date: 20020413,
      stuff: 40,
      amount: 1,
    },
    {
      date: 20020413,
      stuff: 22,
      amount: 3,
    },
    {
      date: 20020413,
      stuff: 30,
      amount: 2,
    },
    {
      date: 20020413,
      stuff: 15,
      amount: 1,
    },
    {
      date: 20020413,
      stuff: 60,
      amount: 9,
    },
    {
      date: 20020413,
      stuff: 75,
      amount: 9,
    },
    {
      date: 20020413,
      stuff: 212,
      amount: 8,
    },
    {
      date: 20020413,
      stuff: 26,
      amount: 9,
    },
    {
      date: 20020413,
      stuff: 33,
      amount: 7,
    },
    {
      date: 20020413,
      stuff: 41,
      amount: 3,
    },
  ],
  [
    {
      date: 20020413,
      stuff: 42,
      amount: 2,
    },
    {
      date: 20020413,
      stuff: 41,
      amount: 3,
    },
    {
      date: 20020413,
      stuff: 23,
      amount: 1,
    },
    {
      date: 20020413,
      stuff: 35,
      amount: 4,
    },
    {
      date: 20020413,
      stuff: 16,
      amount: 1,
    },
    {
      date: 20020412,
      stuff: 63,
      amount: 5,
    },
    {
      date: 20020412,
      stuff: 55,
      amount: 3,
    },
    {
      date: 20020412,
      stuff: 24,
      amount: 3,
    },
    {
      date: 20020412,
      stuff: 31,
      amount: 2,
    },
    {
      date: 20020412,
      stuff: 52,
      amount: 1,
    },
    {
      date: 20020412,
      stuff: 43,
      amount: 1,
    },
    {
      date: 20020412,
      stuff: 22,
      amount: 2,
    },
    {
      date: 20020412,
      stuff: 35,
      amount: 1,
    },
    {
      date: 20020412,
      stuff: 58,
      amount: 3,
    },
    {
      date: 20020412,
      stuff: 20,
      amount: 2,
    },
    {
      date: 20020411,
      stuff: 5,
      amount: 3,
    },
    {
      date: 20020411,
      stuff: 23,
      amount: 4,
    },
    {
      date: 20020411,
      stuff: 5,
      amount: 1,
    },
    {
      date: 20020411,
      stuff: 26,
      amount: 1,
    },
    {
      date: 20020411,
      stuff: 98,
      amount: 3,
    },
  ],
];

export { bag_arr, color_obj, order_list };
