import { icons } from './common/modules/js/IconsFunc';
import { bag_arr, color_obj, order_list } from './content/content';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './App.css';
import OrderHelp from './components/OrderHelp';


function App({ cur }) {
  // component

  const MainRightBags = ({ con }) => {
    

    const arr = splitIntoChunk(Object.values(con), 3);
    const key = splitIntoChunk(Object.keys(con), 3);
    
    return (
      <div id='main_right_bags'>
        {
          arr.map((e,i) => {
            return (
              <div className='bags_row' key={`bags_row_key_${i}`}>
                {
                  e.map((e2,i2) => {
                    return (
                      <div onClick={() => locationChange(`/order/${key[i][i2]}`)} className={i2 === 1 ? "bags_col cent" : "bags_col"} key={`bags_col_key_${i2}`}>
                        <div className='b_img'>
                          <img src={`/images/${e2.img}.png`} alt='b_img' />
                        </div>
                        <div className="b_title">
                          {e2.title}
                          {
                            isSold ?
                            [5,1].map((e3,i3) => {
                              return (
                                <img className='bags_status' src={`/images/status/${e3}.png`} alt='s' key={`bags_row_status_key_${i}_${i2}_${i3}`} />
                              )
                            })
                            :
                            e2.status.map((e3,i3) => {
                              return (
                                <img className='bags_status' src={`/images/status/${e3}.png`} alt='s' key={`bags_row_status_key_${i}_${i2}_${i3}`} />
                              )
                            })
                          }
                        </div>
                        <div className="b_price">
                          {jaritsu(e2.price) + "원"}
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    )
  }



  // func

  const locationChange = (loca) => {
    window.scrollTo(0,0);
    window.location = loca;
  }

  const splitIntoChunk = (arr, chunk) => {
    const result = [];
    
    for (let index=0; index < arr.length; index += chunk) {
      let tempArray;
      tempArray = arr.slice(index, index + chunk);
      result.push(tempArray);
    }
    
    return result;
  }

  const jaritsu = (num) => {
    let str = `${num}`;
    let result = '';
  
    for (let i = 1; i < str.length + 1; i++) {
      result = str[str.length - i] + result;
  
      if(i%3 === 0 && i < str.length) {
        result = ',' + result;
      }
    }
  
    return result;
  }

  const highLightRemover = (num) => {    
    const childNodes = document.getElementById('num_list').childNodes;

    const tempArr = [];

    for (let i = 0; i < childNodes.length; i++) {
      const t = childNodes[i];
      
      t.classList.remove("num_now");
      if(t.childNodes[1]) {
        tempArr.push(t);
      }
    }

    tempArr[(num%10) - 1].classList.add("num_now");
  }

  const orderListPageChange = (e) => {
    locationChange("/admin/orderlist/" + e.target.textContent);
  }

  const changeSoldOut = () => {
    window.open('/alert','new', 'scrollbars=yes,resizable=no width=501 height=510, left=0,top=0');
    setSold(true);
  }


  // var

  const [ paymentInfo, setPaymentInfo ] = useState(null);
  const { productId, paymentId, orderListPage } = useParams();

  const [ isSold, setSold ] = useState(false);


  // mount

  useEffect(() => {
    if(paymentId && !paymentInfo) {
      const tempPay = paymentId.split("&");
      setPaymentInfo([tempPay[0], tempPay[1][1], tempPay[2].slice(1)]);
    }

    if(orderListPage) {
      highLightRemover(orderListPage);
    }
  }, [paymentId, paymentInfo, orderListPage])

  return (
    <div className="App">
      <div className='Container'>
        <div id='header'>
          <div id='header_top'>
            <div id="header_top_logo">
              빈스 쇼핑몰
            </div>

            <div className='fBlank'></div>
            
            <div className="header_menu_b" onClick={() => locationChange("/")}>홈</div>
            <div className="header_menu_b" onClick={() => locationChange("/admin/orderlist/1")}>이용안내</div>
            <div className="header_menu_b">장바구니</div>
            <div className="header_menu_b">주문확인</div>
            <div className="header_menu_b">나의쇼핑</div>
            <div className="header_menu_b">공지사항</div>
          </div>

          <div id='header_mid' onClick={changeSoldOut}>
            <div id='header_menu'>
            </div>
          </div>
        </div>

        <div id='main'>
          <div id="main_left">
            {
              cur === 3 ?
              <div id='login' className='menu'>
                <div id='login_true'>안녕하세요 <b>관리자</b>님</div>

                <div style={{height: "10px"}}></div>

                <div className='login_btn_box'>
                  <div className='login_btn' style={{ margin: "5px 40px"}} onClick={() => locationChange("/")}>로그아웃</div>
                </div>

                <div style={{height: "10px"}}></div>

                <div className='login_no'>▶ <span>주문 배송 관리</span></div>
                <div className='login_no'>▶ <span>제품 등록</span></div>
                <div className='login_no'>▶ <span>재고 관리</span></div>
                <div className='login_no'>▶ <span>회원 관리</span></div>
                <div className='login_no'>▶ <span>공지사항</span></div>
              </div>
              :
              <div id='login' className='menu'>
                <div className='login_form'>
                  <div className="login_form_t">아이디</div>
                  <div className='login_form_input'>
                    <input type='text' autoComplete='off' spellCheck="false" />
                  </div>
                </div>
                <div className='login_form'>
                  <div className="login_form_t">비밀번호</div>
                  <div className='login_form_input'>
                    <input type='password' />
                  </div>
                </div>

                <div className='login_btn_box'>
                  <div className='login_btn'>로그인</div>
                  <div className='login_btn'>회원가입</div>
                </div>

                <div className='login_no'>▶ <span>아이디 찾기</span></div>
                <div className='login_no'>▶ <span>비밀번호 찾기</span></div>
              </div>
            }

            <div id='main_left_banner'>
            <div className="mlb_box">
                <img src='/images/left_banner/left_b_4.png' alt='lb' />
              </div>
              <div className="mlb_box">
                <img src='/images/left_banner/left_b_1.png' alt='lb' />
              </div>
              <div className="mlb_box">
                <img src='/images/left_banner/left_b_2.png' alt='lb' />
              </div>
              <div className="mlb_box">
                <img src='/images/left_banner/left_b_3.png' alt='lb' />
              </div>
            </div>

            <div id='main_left_menu' className='menu'>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>신상</span>
              </div>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>세일</span>
              </div>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>스페셜</span>
              </div>
              <div style={{borderTop: "dashed 1px var(--color-high2)", margin: "8px 0"}}></div>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>공지사항</span>
              </div>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>구매후기</span>
              </div>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>다이어리</span>
              </div>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>문의하기</span>
              </div>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>이야기보따리</span>
              </div>
              <div className="main_left_menu">
                <span className='mlm_arr'>▶</span>
                <span>특별한이벤트</span>
              </div>
            </div>

            <div id='main_left_menu_banner_end'>
              <img src='/images/left_banner/left_banner_end.png' alt='lbe' />
            </div>

          </div>

          <div id='main_right'>
            {
              cur === 0 ?
              <div id='main_right_banner'>
                <div id='main_right_banner_1'>
                  <img src='/images/banner1.gif' alt='b1' />
                </div>
                <div id='main_right_banner_2'>
                  <img src='/images/banner2.gif' alt='b2' />
                </div>
              </div>
              :
              <></>
            }

            <div id='main_right_title' style={{ paddingLeft: "15px"}}>
              {
                cur === 0 ?
                "빈스의 신상 가방들"
                :
                cur === 1 ?
                "☆ 빈스 가방 ★"
                :
                cur === 2 ?
                "결제 ♬"
                :
                cur === 3 ?
                "주문 배송 관리"
                :
                "빈스 쇼핑몰"
              }
            </div>

            {
              cur === 0?
              <MainRightBags con={bag_arr} />
              :
              cur === 1?
              <>
                <div id='mr_order'>
                  <div id='mr_order_l'>
                    <div id='mr_order_image'>
                      <img src={`/images/${bag_arr[productId].img}.png`} alt='oi' />
                    </div>
                  </div>

                  <div id="mr_order_r">
                    <div className="mr_order_row">
                      <div id='mr_order_r_name'>
                        {bag_arr[productId].title}
                        {
                          bag_arr[productId].status.map((e,i) => {
                            return (
                              <img className='bags_status' src={`/images/status/${e}.png`} alt='s' key={`bags_row_status2_key_${i}`} />
                            )
                          })
                        }
                      </div>
                    </div>

                    <div className="mr_order_row">
                      <div className="mor_l">
                        <span className='mor_love'>
                          <img src='/images/love.jpg' alt='l' />
                        </span>
                        판매가
                      </div>
                      <div className='mor_colon'>:</div>
                      <div id='mr_order_r_price'>
                        {jaritsu(bag_arr[productId].price) + "원"}
                      </div>
                    </div>

                    <div className="mr_order_row">
                      <div className="mor_l">
                        <span className='mor_love'>
                          <img src='/images/love.jpg' alt='l' />
                        </span>
                        색상
                      </div>
                      <div className='mor_colon'>:</div>

                      <select id="mor_color">
                        <option value="n" defaultChecked>옵션을 선택해주세요</option>
                        <option value="" disabled>---------</option>
                        <option value="0">블랙</option>
                        <option value="1">브라운</option>
                        <option value="2">핑크</option>
                        <option value="3">화이트</option>
                      </select>
                    </div>

                    <div className="mr_order_row">
                      <div className="mor_l">
                        <span className='mor_love'>
                          <img src='/images/love.jpg' alt='l' />
                        </span>
                        수량
                      </div>
                      <div className='mor_colon'>:</div>

                      <select id="mor_amount">
                        <option value="n" defaultChecked>수량을 선택해주세요</option>
                        <option value="" disabled>---------</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>

                    <div className='fBlank'></div>

                    <div id='mr_order_btn'>
                      <div className='login_btn'>장바구니</div>
                      <div className='login_btn' onClick={() => 
                        {
                          const color = document.getElementById('mor_color').value;
                          const amount = document.getElementById('mor_amount').value;

                          if(color !== "n") {
                            if (amount !== "n") {
                              locationChange(`/payment/${productId}&c${color}&a${amount}`);
                            }
                          }

                        }}>구매하기</div>
                    </div>
                  </div>
                </div>

                <div className='oh_title' style={{ paddingLeft: "15px"}}>상품 상세 사진</div>

                <div id='mr_order_pic_box'>
                  <div className="mr_order_pic">
                    <img src='/images/white.png' alt='pic' />
                  </div>
                  <div className="mr_order_pic">
                    <img src='/images/white.png' alt='pic' />
                  </div>
                  <div className="mr_order_pic">
                    <img src='/images/white.png' alt='pic' />
                  </div>
                  <div className="mr_order_pic">
                    <img src='/images/white.png' alt='pic' />
                  </div>
                  <div className="mr_order_pic">
                    <img src='/images/white.png' alt='pic' />
                  </div>
                </div>

                <OrderHelp />
              </>
              :
              cur === 2 ?
              <>
                {
                  paymentInfo ?
                  <div id='mr_payment'>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px"
                    }}>
                      <div className='mp_form' style={{width: "175px"}}>
                        <div className="mp_name">받는 분</div>
                        <div className="mp_input">
                          <input type='text' spellCheck="false" autoComplete='off' />
                        </div>
                      </div>

                      <div className='mp_form' style={{width: "225px"}}>
                        <div className="mp_name">전화번호</div>
                        <div className="mp_input">
                          <input type='text' spellCheck="false" autoComplete='off' />
                        </div>
                      </div>
                      
                    </div>

                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px"
                    }}>
                      <div className='mp_form' style={{width: "450px"}}>
                        <div className="mp_name">주소</div>
                        <div className="mp_input">
                          <input type='text' spellCheck="false" autoComplete='off' />
                        </div>
                      </div>
                    </div>

                    <div style={{paddingLeft: "20px"}}>주문내역</div>

                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      padding: "20px"
                    }}>
                      <div id='mr_order_l'>
                        <div id='mr_order_image'>
                          <img src={`/images/${bag_arr[paymentInfo[0]].img}.png`} alt='oi' />
                        </div>
                      </div>

                      <div id="mr_order_r" style={{borderRight: "none"}}>
                        <div className="mr_order_row">
                          <div id='mr_order_r_name'>
                            {bag_arr[paymentInfo[0]].title}
                            {
                              bag_arr[paymentInfo[0]].status.map((e,i) => {
                                return (
                                  <img className='bags_status' src={`/images/status/${e}.png`} alt='s' key={`bags_row_status2_key_${i}`} />
                                )
                              })
                            }
                          </div>
                        </div>

                        <div className="mr_order_row">
                          <div className="mor_l">
                            <span className='mor_love'>
                              <img src='/images/love.jpg' alt='l' />
                            </span>
                            판매가
                          </div>
                          <div className='mor_colon'>:</div>
                          <div id='mr_order_r_price'>
                            {jaritsu(bag_arr[paymentInfo[0]].price) + "원"}
                          </div>
                        </div>

                        <div className="mr_order_row">
                          <div className="mor_l">
                            <span className='mor_love'>
                              <img src='/images/love.jpg' alt='l' />
                            </span>
                            색상
                          </div>
                          <div className='mor_colon'>:</div>

                          <div>
                            {
                              color_obj[paymentInfo[1]]
                            }
                          </div>
                        </div>

                        <div className="mr_order_row">
                          <div className="mor_l">
                            <span className='mor_love'>
                              <img src='/images/love.jpg' alt='l' />
                            </span>
                            수량
                          </div>
                          <div className='mor_colon'>:</div>

                          <div>
                            {
                              paymentInfo[2] + "개"
                            }
                          </div>
                        </div>

                        <div className="mr_order_row">
                          <div className="mor_l">
                            <span className='mor_love'>
                              <img src='/images/love.jpg' alt='l' />
                            </span>
                            합계
                          </div>
                          <div className='mor_colon'>:</div>

                          <div style={{fontWeight:600}}>
                            {
                              jaritsu(bag_arr[paymentInfo[0]].price * paymentInfo[2]) + "원"
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id='mr_payment_ok_box'>
                      <div className='login_btn mr_payment_ok' onClick={() => 
                          locationChange(`/`)
                      }>
                        주문취소
                      </div>

                      <div className='login_btn mr_payment_ok' onClick={() => 
                          locationChange(`/`)
                      }>
                        주문확인
                      </div>
                    </div>
                  </div>
                  :
                  <></>
                }
              </>
              :
              <>
                <div id='order_list'>
                  <div className="ol_row" id='ol_header'>
                    <div className="ol_row_1">주문번호</div>
                    <div className="ol_row_2">주문일자</div>
                    <div className="ol_row_3">제품명</div>
                    <div className="ol_row_4">결제방법</div>
                    <div className="ol_row_5">결제금액</div>
                    <div className="ol_row_6">배송상태</div>
                  </div>

                  {
                    order_list[(orderListPage - 1) % 3].map((e,i) => {
                      let cur_bag = bag_arr[20021450];

                      if(e.stuff > 0) {
                        cur_bag = bag_arr[(e.stuff % 7) + 20021450];
                      }
                      
                      const date = String(e.date);

                      return (
                        <div className="ol_row" key={`ol_row_key_${i}`}>
                          <div className="ol_row_1">{40040830876 - i - (20 * (orderListPage - 1))}</div>
                          <div className="ol_row_2">{date.slice(0,4) + "." + date.slice(4,6) + "." + date.slice(6)}</div>
                          <div className="ol_row_3">{cur_bag.title || "undefined"}</div>
                          <div className="ol_row_4">신용카드</div>
                          <div className="ol_row_5">{jaritsu(cur_bag.price * e.amount) + "원"}</div>
                          <div className="ol_row_6">주문확인중</div>
                        </div>
                      )
                    })
                  }
                </div>
                  
                <div id='num_list'>
                  <div style={{fontSize: "12px", marginRight: "5px", width: "80px"}}>전체 : 103</div>
                  {/* <div className='btn'>◁</div> */}
                  <div className='num_btn num_now'>[
                    <span onClick={orderListPageChange}>1</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>2</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>3</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>4</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>5</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>6</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>7</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>8</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>9</span>
                  ]</div>
                  <div className='num_btn'>[
                    <span onClick={orderListPageChange}>10</span>
                  ]</div>
                  <div className='num_btn' style={{width: "80px"}}>▶</div>
                </div>
              </>
            }

            
            
            
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;