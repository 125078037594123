function OrderHelp() {
  return (
    <div id='order_help'>
      <div className='oh_title' style={{ paddingLeft: "15px"}}>주문하기전에 필독해주세요</div>

      <div className='oh_index'>- 결제안내</div>
      <p>결제는 무통장입금, 실시간이체, 카드결제를 이용하시면 됩니다.</p>
      <p>주문서를 작성하시고 2일이내 입금확인 되지 않으면 자동으로 주문서가 취소됩니다.</p>
      <p>주문서에 작성하신 입금자명과 금액이 일치해야 자동입금 확인처리가 가능합니다.</p>
      <p>혹시, 다르게 입금하셨을 경우 게시판이나, 고객센터로 연락을 꼭 주셔야 합니다.</p>
      <p>24시간 이내 입금확인이 되지 않으면 게시판, 고객센터로 연락을 꼭 주셔야 합니다.</p>

      <div className='oh_index'>- 배송안내</div>
      <p>기본배송일은 1일~3일이며, 사정에 따라 3~5일 배송이 지연될 수 있습니다.</p>
      <p>배송지연이 오래되는 경우, 개별적으로 연락을 드립니다. 7만원 이상 구매시 배송료는 무료입니다.</p>

      <div className='oh_index'>- 교환반품안내</div>
      <p>반품의 경우는 재포장비, 인건비, 세탁비 등 추가비용이 들어가게 됩니다.</p>
      <p>고객님들의 좀 더 신중한 구매를 유도하고자 상품하자, 적립금환불을 제외하고 즉 계좌환불의 경우</p>
      <p>배송료+1000원을 더 받습니다. 공지사항을 숙지하지 못하여 불이익이 발생하지 않도록 주의 부탁드립니다.</p>
      <p>교환의 경우 왕복배송료 5천원을 동봉해서 보내주세요. 환불의 경우 배송료+1000원을 동봉해서 보내주세요.</p>
      <p>카드결제, 계좌이체 취소의 경우 처음 지불하셨던 배송료까지 취소되므로 5천원+1000원을 동봉하여 보내주세요.</p>
      <p>교환&반품은 물품수령후 2일 이내에 고객센터 쪽으로 알려주셔야 합니다.</p>
      <p>교환&반품 접수는 모든문의 Q&A 게시판 & 고객센터로 해주셔야 합니다.</p>
      <p>반품접수를 하시게 되면 상담원의 안내에 따라 상품을 보내주시면 됩니다.</p>
      <p>고객센터로 전화해주시면 교환반품접수, 택배기사 방문접수까지 해드립니다.</p>
      <p>수령 후 7일이내에 저희쪽에서 수령할수 있도록 상품을 발송해주세요.</p>
      <p>하자인 경우, 빈스에서 왕복배송료를 지불합니다. 하자인 경우는 동일색상, 동일사이즈로만 교환 가능합니다.</p>
      <p>무료배송을 목적으로 상품을 임의훼손하는 것을 막기위함입니다.</p>
      <p>하자인 경우, 환불을 원하시면 단순변심으로 간주해 배송료는 고객님께서 부담합니다.</p>
      <p>오배송의 경우, 빈스에서 왕복배송료를 지불하며 동일상품, 동일색상, 동일사이즈로 교환 가능합니다.</p>

      <div className="oh_index">- 교환&반품이 안되는 경우</div>
      <p>*세일상품 / 반품기간이 경과된 경우 / 적립금으로 구매하신경우 / 주문하신상품이 품절된경우</p>
      <p>*교환&반품 불가라고 표시된 경우 / 교환&반품 의사를 밝히지 않고 보내신 경우</p>
      <p>*제품 착용흔적, 세탁후, 냄새가 나는 경우 - 제품의 하자인 경우에도 절대 교환&환불이 되지 않습니다.</p>
      <p>*고객님의 부주의로 인해 상품의 변형, 훼손되어 상품가치가 사라진경우</p>
      <p> - 제품의 하자인 경우에도 절대 교환&환불이 되지 않습니다.</p>
    </div>
  );
}

export default OrderHelp;